import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import Link from 'gatsby-link'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { Article, TitleParagraph } from '@global'

const GroupIndex = ({ data }) => (
  <Layout>
    <Seo title="Community Groups | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Community Groups" />
    <TitleParagraph>Below is a list of community groups and organisations In Sowerby Bridge</TitleParagraph>
    <TitleParagraph>If you would like your community group listed below then please contact us</TitleParagraph>
    <Article>
      {data.allDatoCmsCommunityLink.edges.map(({ node }) => (
        <div className="post">
          <h1 className="post-title">
            <Link to={`/community/${node.slug}`}>
              <h2>{node.title}</h2>
            </Link>
          </h1>
        </div>
      ))}
    </Article>
  </Layout>
)

export const Groupgraph = graphql`
  {
    allDatoCmsCommunityLink(filter: { organisation: { title: { eq: "Group" } } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default GroupIndex
